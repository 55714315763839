<template>
  <div>
    <b-list-group>
      <b-list-group-item v-for="(item, index) in content" :key="index">
        {{ lookupSource(item.id) }}
        <b-row v-if="sources[item.id] != undefined">
          <b-col>
            {{ sources[item.id] }}
          </b-col>
          <b-col sm="auto">
            <b-button
              v-t="'pages.sources.delete'"
              @click="removeSource(index)"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <base-auto-complete
      :graph-query="completions"
      :placeholder="$t('pages.sources.select')"
      @select="addSource"
    >
      <!-- What to show when we have no results -->
      <template v-slot:noResult>
        <span>{{ $t("components.baseList.noResult") }}</span>
      </template>
      <!-- Template for each element in the auto complete -->
      <template v-slot:option="{ option: { name } }">
        <span>{{ name }}</span>
      </template>
      <!-- Template for a selected element in the auto complete -->
      <template v-slot:singleLabel="{ option: { name } }">
        <span>{{ name }}</span>
      </template>
    </base-auto-complete>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "SourcesSelector",
  components: {
    BaseAutoComplete: () => import("@/components/BaseAutoComplete")
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      sources: {},
      completions: gql`
        query searchByAbbreviation($search: String!) {
          completions: findSourceByAbbreviation(abbreviation: $search) {
            name: abbreviation
            id
          }
        }
      `
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    addSource(element) {
      this.content.push({ id: element.id });
      this.$set(this.sources, element.id, element.name);
      this.$emit("change");
    },
    removeSource(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    lookupSource(id) {
      if (this.sources[id] != undefined) return;
      this.$apollo
        .query({
          query: gql`
            query source($id: ID) {
              source(id: $id) {
                id
                abbreviation
              }
            }
          `,
          variables: { id: id }
        })
        .then(result => {
          this.$set(
            this.sources,
            result.data.source.id,
            result.data.source.abbreviation
          );
        });
    }
  }
};
</script>
